<template>
  <div class="internPaymentDetailBody">
    <PocCardTable class="internPaymentDetail">
<!--      <template v-slot:headerTitle>Intern Repo</template>-->
<!--      <template v-slot:headerGroup>-->
<!--        <el-button @click="search" type="primary">查询</el-button>-->
<!--        <el-button @click="download" type="primary">下载</el-button>-->
<!--        <el-button @click="goback">返回</el-button>-->
<!--      </template>-->
      <template>
        <div class="flexBetw opeartionBox">
          <div class="pcth-title-left">Intern Repo</div>
          <div>
            <el-button @click="search" type="primary">查询</el-button>
            <el-button @click="download" type="primary">下载</el-button>
            <el-button @click="goback">返回</el-button>
          </div>
        </div>
      </template>
      <template>
        <el-form
          :inline="true"
          label-position="top"
          :model="formInline"
          class="personnelRequisitionForm"
        >
          <el-form-item label="HFM Code">
            <el-select
              v-model="formInline.hfmCode"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in hfmCodes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Brand">
            <el-select
              v-model="formInline.brand"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in brands"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Department">
            <el-select
              v-model="formInline.department"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in departments"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Location">
            <el-select
              v-model="formInline.location"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in locations"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Name" label-position="top">
            <el-input placeholder="" v-model="formInline.name"> </el-input>
          </el-form-item>
        </el-form>
      </template>
    </PocCardTable>
    <PocCardTable style="margin-top: 20px">
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        size="large"
        :max-height="maxHeight"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        highlight-current-row
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            <div>
              <div v-if="item.key==='isTaxpayer' || item.key==='moreNinty' ">
                 {{scope.row[item.key] ? '是' : '否'}}
              </div>
              <div v-else>{{scope.row[item.key]}}</div>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
  </div>
</template>

<script>
import { internPaymentDetailTableColumn } from '@/views/payroll/TableColumn'
import {
  getPayrollInternDetailList,
  getPayrollInternHfmCodeList,
  getPayrollBrandList,
  getPayrollInternDepartmentList,
  getPayrollInternLocationList
} from '@/api/payroll'
import { internExport } from '@/api/payrollReport'

import PocSlider from '@/components/poc-slider/poc-slider'
const { Message } = $PCommon
export default {
  name: 'actData',
  mixins: [$PCommon.TableMixin],
  components: {
    PocSlider
  },

  data() {
    return {
      loading: false,
      columnConfig: internPaymentDetailTableColumn,
      tableData: [],
      pageConfig: {
        total: 1,
        pageNum: 1
      },
      formInline: {
        hfmCode: '',
        brand: '',
        department: '',
        location: '',
        name: '',
        pageSize: 10,
        pageNum: 1,
        periodUploadRecord: ''
      },
      hfmCodes: [],
      brands: [],
      departments: [],
      locations: []
    }
  },
  computed: {},
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 330
    this.formInline.periodUploadRecord = this.$route.query.id
    this.queryApi()
    this.getFormLineData()
  },
  mounted() {},
  methods: {
    async getFormLineData() {
      getPayrollInternHfmCodeList().then((res) => {
        this.hfmCodes = res.data
      }),
        getPayrollBrandList().then((res) => {
          this.brands = res.data
        }),
        getPayrollInternDepartmentList().then((res) => {
          this.departments = res.data
        }),
        getPayrollInternLocationList().then((res) => {
          this.locations = res.data
        })
    },

    search() {
      getPayrollInternDetailList(this.formInline).then((res) => {
        this.tableData = res.data.list
        this.pageConfig = res.data
      })
    },

    queryApi() {
      this.search()
    },

    pageSizeChange(val) {
      this.formInline.pageSize = val
      this.queryApi()
    },

    pageChange(val) {
      this.formInline.pageNum = val
      this.queryApi()
    },

    download() {
      internExport(this.formInline)
    },

    goback() {
      this.$router.push({
        path: '/pcTracker/payroll',
        query: {
          id: 'internPayroll',
          title: 'internPayroll'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.internPaymentDetailBody {
  overflow-x: hidden;
  .flexBetw {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-form .el-form-item {
    margin: 16px 16px 0 0 !important;
  }
  .internPaymentDetail {
    .topTitle {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
